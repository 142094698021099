import 'bootstrap/dist/css/bootstrap.min.css';

import './Home.css';

import profile from "../images/profile.jpg"
import hero from "../images/hero.jpg"
import work from "../images/work.jpeg"
import sessions from "../images/sessions.jpg"


import Container from 'react-bootstrap/Container'
import React from 'react';

import Header from "../Header/Header"

function Home() {
  return (
    <React.Fragment>

      <Header />

      <div style={{ backgroundImage: `url(${hero})`, backgroundRepeat: `no-repeat` }} className="hero">

        <div className="p-3">
          <h1 className="text">Wholehearted Psychotherapy</h1>
        </div>
      </div>
      
      <div className="section">
      <div className="d-flex flex-row">
        <div>
          <img className="about-image" src={profile}></img>
        </div>

        <div>
          <h4 className="text font-weight-light">Welcome to Wholehearted Psychotherapy!</h4>
          <p className="text">

            My name is Gayathri Ram Mohan, Licensed Clinical Social Worker MSW, MA (Holistic Counseling Psychology), LCSW #102168.

            <br></br><br></br>

            Some life experiences create open wounds that can have a paralyzing impact in the way we see the world, interact or make meaning.  This can cause a certain numbness to life, leading one to shy away from intimate relationships and struggle with boundaries among friends or family.

            <br></br><br></br>

            Sometimes it is the critical voice in our heads that hijacks our whole being, leading us to spiral into the belief of “I am not okay.” I strive to provide a compassionate and non-judgmental space – a space where you have the chance to learn, reflect, and plant seeds of self-compassion. I look forward to guiding through each person’s unique journey of introspection and understanding.

            <br></br><br></br>

            Poet Rumi sums up my philosophy when he writes <span className='bold'>“out beyond ideas of wrong doing and right doing there is a field. I’ll meet you there.”</span> This is the space I strive to provide in therapy.

            <br></br><br></br>

            I have worked through my own trauma, insecurities surrounding my body, gender and diversity in therapy. I understand how difficult it is to bring these topics to the therapy room. I bring sensitivity and compassion so individuals & couples can explore, introspect and make meaning of their experiences. Transcending and living a full and wholesome life is possible.

            <br></br><br></br>

            You can contact me to schedule a free 15-minute consultation at (408) 412-9891
          </p>
        </div>
      </div>
      


        {/* <div className="section">
          <div>
            <img className="work-image" style={{ float: "right" }} src={work}></img>
          </div>

          <div>
            <h4 className="text">I work with adults and teens to address</h4>
            <ul>
              <li className="text">Anxiety</li>
              <li className="text">Depression</li>
              <li className="text">Substance Abuse</li>
              <li className="text">PTSD & Trauma</li>
              <li className="text">Race, Gender, and Identity issues</li>
              <li className="text">Grief and Loss</li>
              <li className="text">Self Esteem</li>
              <li className="text">Meaning, Purpose, and Mindfulness</li>
              <li className="text">Relationships, Marriage and Family counseling</li>
            </ul>
          </div>
        </div> */}



        {/* <div className="section">
          <div className="d-flex flex-direction-row">
            <div>
              <img className="work-image" style={{ float: "right" }} src={sessions}></img>
            </div>

            <div id="offerings">
              <h4 className="text">Current Offerings</h4>

              <h6 className="text">Individual</h6>
              <ul>
                <li className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
              </ul>
              <h6 className="text">Group</h6>
              <ul>
                <li className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</li>
              </ul>
            </div>
          </div>
        </div> */}

        {/* <div className="section">

          <div style={{ height: "30vh" }} className="contact-container d-flex flex-column justify-content-between align-items-center">
            <h2 className="text">Contact</h2>

            <h3 className="text">gayathri3therapy [at] gmail [dot] com</h3>
            <h4><a className="text" href="https://www.psychologytoday.com/us/therapists/gayathri-ram-mohan-san-jose-ca/489512" target="_blank">Psychology Today Profile</a></h4>
            <h4 className="text">+ 1 (408) 412-9891</h4>
            <h4 className="text">1760 The Alameda, San Jose, CA 95126 Suite 100</h4>
          </div>
        </div> */}





      </div>


    </React.Fragment>
  );
}

export default Home;
