import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import Container from 'react-bootstrap/Container'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Home/Home";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Fees from "./Fees/Fees"
import React from 'react';

function App() {
  return (
     <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/home" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path={"/contact"} element={<Contact/>} />
      </Routes>
    </Router>
  );
}

export default App;
