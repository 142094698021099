import 'bootstrap/dist/css/bootstrap.min.css';

import '../Home/Home.css'
import './About.css';

import Container from 'react-bootstrap/Container'
import React from 'react';

import hero from "../images/about.jpg"
import profile from "../images/profile.jpg"

import Header from "../Header/Header"

function About() {
  return (
    <React.Fragment>

      <Header />

      <div className="section">
        <div className='container-sm'>

          <h4 className="text font-weight-light">About Gayathri Ram Mohan Licensed Clinical Social Worker MSW, MA (Holistic Counseling Psychology)</h4>
          <p className="text">
            I am a Licensed Clinical Social Worker #102168. I have over a decade of experience with working with diverse populations ranging from pre-schoolers, elementary to high school students, youth and adults.
            <br></br><br></br>
            I have worked in community settings including with the homeless, both in Mumbai, India and in California. I provide low fee services working with local non-profits and am currently a clinical consultant at Narika and the Avenidas Rose Kleiner Center. 
            <br></br><br></br>
            I also worked with Seniors and have a deep understanding of how care-giving impacts mental health and wellbeing. I have facilitated support groups for care-givers, for grief, for anxiety and depression. 
            <br></br><br></br>
            I am a trauma-informed therapist, an EMDR (EMDRIA) trained clinician and a certified Mindfulness trainer and practitioner. I also facilitate "Interpersonal Dynamics", a process group for students at the Stanford Graduate School of Business. I bring a wholehearted approach which includes somatic, experiential and mindfulness as part of the therapeutic process.
            <br></br><br></br>
            I offer individual, couples and family therapy both in person and virtual.
          </p>


          <div className="adaptiveEducation">
            <div>
              <h4 className="text">Education</h4>
              <h6 className="text">John F Kennedy University, 2016</h6>
              <p className="text bold">MA in Counseling Psychology</p>
              <h6 className="text">Tata Institute of Social Science, 1988</h6>
              <p className="text bold">MSW in Family and Child Welfare</p>
              <h6 className="text">University of Bombay, 1986</h6>
              <p className="text bold">BA in Economics</p>
            </div>
            <div>
              <h4 className="text">Training</h4>
              <div>
                <h6 className="text">EMDR (Eye Movement Desensitization and Reprocessing)</h6>
                <h6 className="text">Interpersonal Dynamics Course Instructor</h6>
              </div>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>
  );
}

export default About;
