import 'bootstrap/dist/css/bootstrap.min.css';

import '../Home/Home.css'
import './Contact.css';


import Container from 'react-bootstrap/Container'
import React from 'react';

import Header from "../Header/Header"
import feeImage from "../images/fees.jpeg"

import hero from "../images/contact.jpg"

function Contact() {
  return (
    <React.Fragment>
      <Header />

      <div className="section">

      <div className="section d-flex flex-direction-row justify-content-center">


        <div className="container p-2">
          <h2 className="text">Contact Me</h2>
          <p className="text">
            <br></br>

            Gayathri Ram Mohan, Licensed Clinical Social Worker MSW, MA (Holistic Counseling Psychology), LCSW #102168
            <br></br><br></br>
            <a href="tel:4084129891">(408) 412-9891</a>
            <br></br><br></br>
            <a href="mailto: gaya3therapy@gmail.com">gaya3therapy@gmail.com</a>
            <br></br><br></br>
            <a href="https://www.psychologytoday.com/us/therapists/gayathri-ram-mohan-san-jose-ca/489512" target="_blank">Psychology Today Profile</a>
            <br></br><br></br>
            Office Location:
            <br></br><br></br>
            1760 The Alameda, Suite #100, San Jose, CA 95126
          </p>

        </div>
          <div>
            <img className="contact-image" src={feeImage}></img>
          </div>
          
        </div>

      </div>


    </React.Fragment>
  );
}

export default Contact;
