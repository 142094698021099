import '../Home/Home.css';

import Container from 'react-bootstrap/Container'
import React from 'react';
import {Link} from "react-router-dom";

import { HashLink as Link2 } from 'react-router-hash-link';

function Header() {
  return (
    <React.Fragment>
        <div className="p-4 header d-flex flex-row align-items-center">            
            <div className="a-container">
            <Link className="p-2 text" to={"/home"}>{"Home"}</Link>
            <Link className="p-2 text" to={"/about"}>{"About"}</Link>
            <Link className="p-2 text" to={"/contact"}>{"Contact"}</Link>
            {/* <Link className="p-2 text" to={"/fees"}>{"Fees"}</Link> */}
            </div>
        </div>
    </React.Fragment>
  );
}

export default Header;